import { Link, graphql } from 'gatsby';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { formatTitle } from '../../utils/shop';
import { titleCase } from '../../utils/title-case';
import { getSliceNumber } from '../../utils/podCast';
import SocialShare from '../../components/Shop/SocialShare';
import ReadPageContent from '../../components/Read/ReadPageContent';
import RelatedContent from '../../components/podcast/RelatedContent';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import { formatReadDuration } from '../../utils/functions';
import { client } from '../../apollo/client';
import Stickysidebar from '../../components/Read/Stickysidebar';

const isListEmpty = (arr) => {
  if (Array.isArray(arr) && !!arr?.length) {
    return false;
  }

  return true;
};

export function ReadSingleRender({ read, podCastData, readData, totalWatchData }) {
  var seoData = {
    canonical: read?.uri,
    title: titleCase(read?.title?.text),
    metaDesc: read?.metaDescriptions?.text || `Read more about ${titleCase(read?.title.text)}`,
    opengraphDescription: read?.metaDescriptions?.text || `Read more about ${titleCase(read?.title.text)}`,
    twitterDescription: read?.metaDescriptions?.text || `Read more about ${titleCase(read?.title.text)}`,
    opengraphTitle: titleCase(read?.title.text) || '',
    twitterTitle: titleCase(read?.title.text) || '',
    opengraphImage: {
      sourceUrl: read?.featuredImage?.node?.sourceUrl,
    },
    twitterImage: {
      sourceUrl: read?.featuredImage?.node?.sourceUrl,
    },
    opengraphUrl: read?.uri || '',
  };

  if (!read) {
    console.error('read undefined in read-page');

    return null;
  }

  const {
    title,
    readDuration,
    postBanner,
    furtherReading,
    furtherListening,
    footnotes,
    featuredVideo,
    featuredVideoThumbnail,
    readCategories,
    featuredPodcast,
  } = read;
  const relatedListen = read.relatedListen
    ?.filter((content) => content?.relatedListen?.uid)
    .map((item) => {
      const pod = podCastData.nodes.find((pod) => pod.uid === item.relatedListen.uid);
      return {
        ...pod,
        category: 'LISTEN',
        url: `/podcast/${pod.uid}`,
      };
    });

  const relatedEpisodes = read.relatedEpisodes
    ?.filter((content) => content?.episode?.uid)
    .map((item) => {
      const watch = totalWatchData.nodes.find((watch) => watch.uid === item.episode.uid);

      return {
        ...watch,
        category: 'WATCH',
        uid: item.episode.uid,
      };
    });

  const relatedRead = read.relatedRead
    ?.filter((content) => content?.read?.uid)
    .map((item) => {
      const read = readData.nodes.find((read) => read.uid === item?.read?.uid);
      return {
        ...read.data,
        category: 'READ',
        url: read.url,
      };
    });

  const relatedShop = read.relatedProducts
    .filter((item) => !!item.product)
    .map((p) => {
      return {
        thumbnailUrl: p.product.image.src,
        category: 'SHOP',
        title: p.product.title,
        url: `/product/${formatTitle(p.product.title)}`,
      };
    });

  const relatedReadCount = relatedRead?.length;
  const relatedEpisodesCount = relatedEpisodes?.length;
  const relatedShopCount = relatedShop?.length;
  const relatedListenCount = relatedListen?.length;

  let relatedData = [];

  if (relatedEpisodes.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedEpisodes.slice(0, sliceNumber));
  }
  if (relatedRead.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedShopCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedRead.slice(0, sliceNumber));
  }

  if (relatedShop.length) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedListenCount, relatedData);
    relatedData = relatedData.concat(relatedShop.slice(0, sliceNumber));
  }
  if (relatedListenCount) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedShopCount, relatedData);
    relatedData = relatedData.concat(relatedListen.slice(0, sliceNumber));
  }

  const furtherListeningList = furtherListening.map((fListening) => fListening?.listening?.text).filter((e) => e);
  const furtherReadingList = furtherReading.map((fReading) => fReading?.reading?.text).filter((e) => e);
  const footNotesList = footnotes.map((elem) => elem?.footnote?.text).filter((e) => e);

  const totalRelatedContentsCount = 4;
  // check relateddata is less than 4
  if (relatedData.length < totalRelatedContentsCount) {
    const readdatas = readData.nodes;
    const allReadData = readdatas.map((item) => ({
      ...item.data,
      category: 'READ',
      url: item.url,
    }));
    const additionalDataCount = totalRelatedContentsCount - relatedData.length;
    const additionalData = allReadData.sort(() => Math.random() - 0.5).slice(0, additionalDataCount);
    relatedData = [...relatedData, ...additionalData];
  }

  //filter podcast data using podcast uid
  const podcastUid = featuredPodcast.uid;
  const podcast = podCastData?.nodes.find((pod) => pod.uid === podcastUid);

  return (
    <BodyClassName className="body-dark page-read page-read--single">
      <DefaultLayoutComponent seo={seoData} description={read?.seo?.metaDesc}>
        <div className="site-main site-readpage">
          <div className="wrapper wrapper-xl site-readpage">
            <div>
              <Stickysidebar featuredVideo ={featuredVideo} featuredPodcast={podcast} />
            </div>
            <div className="wrapper wrapper-xl">
              <div className="read-body bg-transparent">
                <div className="wrapper wrapper-xl pd-x-md ">
                  {/* <Stickysidebar /> */}
                  <div className="wrapper wrapper-xl  pd-x-md ">
                    <div class="section r_s_title bg-light pd-t-0">
                      <div className="wrapper wrapper-xl post-title pd-x-md bg-light">
                        <h3 className="sectionHeading">{titleCase(title.text)}</h3>
                        {readDuration?.raw?.length > 0 && readDuration?.raw[0]?.text && (
                          <div className="m_run-time">
                            <span>{formatReadDuration(readDuration?.raw[0]?.text)}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ReadPageContent
                category={readCategories}
                body={read?.body1}
                featuredVideo={featuredVideo}
                postBanner={featuredVideoThumbnail?.fluid?.src}
                featuredPodcast={podcast}
              />
              <div id='references' className="read-footer readfooter-bg pd-x-md">
                <div className="wrapper wrapper-xl pd-x-md">
                  <div className="section r_s_footer-info pd-t-0 pd-x-sm">
                    <div className="wrapper wrapper-md pd-x-sm">
                      <div className="wrapper-sm">
                        <SocialShare title={read.title} />
                        {!isListEmpty(furtherReadingList) && (
                          <div  className="r_s_author">
                            <h6 className="r_s_author-name">Further Reading</h6>
                            {furtherReading.map((fReading) => (
                              <p className="r_s_author-desc">{fReading?.reading?.text}</p>
                            ))}
                          </div>
                        )}
                        {!isListEmpty(furtherListeningList) && (
                          <div  className="r_s_author">
                            <h6 className="r_s_author-name">Further Listening</h6>
                            {furtherListening.map((fListening) => (
                              <p className="r_s_author-desc">{fListening?.listening?.text}</p>
                            ))}
                          </div>
                        )}
                        {!isListEmpty(footNotesList) && (
                          <div className="r_s_author">
                            <h6 className="r_s_author-name">Footnotes</h6>
                            {footnotes.map((fnote) => (
                              <p className="r_s_author-desc">{fnote?.footnote?.text}</p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {relatedData?.length ? (
                    <div style={{ marginTop: '121px' }}>
                      <RelatedContent  relatedContent={relatedData} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
}

function ReadSinglePage(props) {
  const {
    data: {
      read: { nodes },
      readData,
      totalWatchData,
      podCastData,
    },
  } = props;

  // const [read, setRead] = useState(readData);

  let singlePageReadData = [];
  if (Array.isArray(nodes)) {
    singlePageReadData = nodes[0];
  }

  return (
    <ReadSingleRender
      read={singlePageReadData?.data}
      podCastData={podCastData}
      readData={readData}
      totalWatchData={totalWatchData}
    />
  );
}

export const query = graphql`
  query SingleReadPageQuery($id: String!) {
    read: allPrismicRead(filter: { id: { eq: $id } }) {
      nodes {
        data {
          footnotes: footnotes1 {
            footnote {
              text
            }
          }
          title {
            text
          }
          body1 {
            ... on PrismicReadBody1Text {
              id
              primary {
                backgroundColor: background_color
                sectionContents: section_contents {
                  text: html
                }
              }
              sliceType: slice_type
            }
            ... on PrismicReadBody1Photo {
              id
              items {
                image {
                  alt
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              sliceType: slice_type
              primary {
                backgroundColor: background_color
              }
            }
            ... on PrismicReadBody1Video {
              id
              primary {
                backgroundColor: background_color
                videoId: video_id {
                  text
                }
              }
              sliceType: slice_type
            }
            ... on PrismicReadBody1Quote {
              id
              primary {
                backgroundColor: background_color
                sectionContents: section_contents {
                  text
                }
              }
              sliceType: slice_type
            }
          }
          relatedEpisodes: related_episodes {
            episode {
              uid
              type
              slug
            }
          }
          relatedRead: related_reads {
            read {
              uid
              type
              slug
            }
          }
          readCategories: read_categories {
            read_category {
              uid
              type
            }
          }
          relatedListen: related_listens {
            relatedListen: related_listen {
              uid
              id
              type
            }
          }
          relatedProducts: related_products {
            product
          }

          furtherReading: further_reading1 {
            reading {
              text
            }
          }
          metaDescriptions: meta_description {
            text
          }
          featuredVideo: featured_video {
            text
          }
          featuredPodcast: featured_podcast {
            uid
            slug
          }
          featuredVideoThumbnail: featured_video_thumbnail {
            fluid(maxWidth: 1400) {
              ...GatsbyPrismicImageFluid
            }
          }
          featuredImage: featured_image {
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
          }
          furtherListening: further_listening1 {
            listening {
              text
            }
          }
          postBanner: post_banner1 {
            fluid(maxWidth: 1400) {
              ...GatsbyPrismicImageFluid
            }
          }

          readDuration: read_duration1 {
            raw
          }
        }
        id
        url
        last_publication_date
        first_publication_date
      }
    }
    totalWatchData: allPrismicEpisodes(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          title {
            text
          }
          videoDuration: video_duration {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          youtubeId: youtube_id {
            text
          }
        }
        uid
      }
    }
    podCastData: allPrismicPodcast(sort: { fields: first_publication_date, order: DESC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          podcastId: podcast_id {
            text
          }
          miniPlayerThumbNail: mini_player_thumbnail {
            url
          }

          season {
            type
            uid
          }
          podcastDuration: podcast_duration
        }
        uid
      }
    }

    readData: allPrismicRead(sort: { fields: data___priority }) {
      nodes {
        data {
          title {
            text
          }
          readDuration: read_duration1 {
            text
          }
          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        url
        uid
      }
    }
  }
`;

export default ReadSinglePage;
